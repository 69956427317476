/**
 * 仓库
 */
export default {
    set uid(value) {
        localStorage.setItem('uid', value)
    },

    get uid() {
        return localStorage.getItem('uid')
    },
    set rules(value) {
        localStorage.setItem('rules', value)
    },

    get rules() {
        return localStorage.getItem('rules')
    },

    set token(value) {
        localStorage.setItem('token', value)
    },

    get token() {
        return localStorage.getItem('token')
    },
    set token_timeout(value) {
        localStorage.setItem('token_timeout', value)
    },

    get token_timeout() {
        return localStorage.getItem('token_timeout')
    },
    set myName(value) {
        localStorage.setItem('myName', value)
    },
    get myName() {
        return localStorage.getItem('myName')
    },



}
