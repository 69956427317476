<template>
    <a-layout id="" style="min-height: 100vh">
        <a-layout-header class="header">
            <div class="logo"/>
        </a-layout-header>
        <a-layout>
            <a-layout-sider collapsible v-model="collapsed" width="200">
                <div class="logo"/>
                <a-menu
                        :defaultSelectedKeys="[$route.path]"
                        :selectedKeys="[$route.path]"
                        mode="inline"
                        theme="dark"

                >
                    <a-menu-item :key="'/'">
                        <a-icon type="pie-chart"/>
                        <span><router-link to="/">首页</router-link></span>
                    </a-menu-item>


                    <a-sub-menu key="'company'">
                    <span slot="title">
                        <a-icon type="team"/>
                        <span>公司管理</span>
                    </span>

                        <a-menu-item :key="'/adminList'">
                            <router-link to="/adminList">员工列表</router-link>
                        </a-menu-item>

                        <a-menu-item :key="'/adminGroup'">
                            <router-link to="/adminGroup">部门管理</router-link>
                        </a-menu-item>

                        <a-menu-item :key="'/adminPermiss'">
                            <router-link to="/adminPermiss">职务管理</router-link>
                        </a-menu-item>

                    </a-sub-menu>
                    <a-sub-menu key="'Category'">
                    <span slot="title">
                        <a-icon type="align-left"/>
                        <span>网站分类管理</span>
                    </span>

                        <a-menu-item :key="'/webindex'">
                            <router-link to="/webindex">首页管理</router-link>
                        </a-menu-item>
                        <a-menu-item :key="'/articleCategory'">
                            <router-link to="/articleCategory">类目管理</router-link>
                        </a-menu-item>
                    </a-sub-menu>
                    <a-sub-menu key="'article'">
                    <span slot="title">
                        <a-icon type="file-text"/>
                        <span>文章管理</span>
                    </span>

                        <a-menu-item :key="'/articlemanger'">
                            <router-link to="/articlemanger">文章管理</router-link>
                        </a-menu-item>
                        <a-menu-item :key="'/productionmanger'">
                            <router-link to="/productionmanger">作品管理</router-link>
                        </a-menu-item>
                        <a-menu-item :key="'/recruitmentmanger'">
                            <router-link to="/recruitmentmanger">招聘管理</router-link>
                        </a-menu-item>
                        <a-menu-item :key="'/specialmanger'">
                            <router-link to="/specialmanger">特殊页管理</router-link>
                        </a-menu-item>
                        <a-menu-item :key="'/articleComments'">
                            <router-link to="/articleComments">评论管理</router-link>
                        </a-menu-item>

<!--                        <a-menu-item :key="'/additem'">-->
<!--                            <router-link to="/additem">商品管理</router-link>-->
<!--                        </a-menu-item>-->

                    </a-sub-menu>


                    <a-menu-item :key="9" @click="onLogout">
                        <a-icon type="file"/>
                        <span>退出</span>
                    </a-menu-item>
                </a-menu>
            </a-layout-sider>
            <!--            内部嵌入页面-->
            <router-view/>
        </a-layout>
    </a-layout>
</template>
<script>
    import {Modal} from "ant-design-vue";
    import Storage from "../common/storage";
    // import moment from "moment";
    // import * as Util from "../common/util";


    // import moment from "moment";

    export default {
        name: "index",
        components: {},
        data() {
            return {
                permissions: [],
                collapsed: false,
                loading: false,
            };
        },
        created() {
            // Promise.all([this.getCategory(),
            //     console.log(Storage.Categorydata)
            // ]).then(() => {
            //     this.loading = false;
            // }).catch((error) => {
            //     console.log(error)
            // })
            let now = Date.now() / 1000;
            //console.log("now: ", now);
            //console.log("token_timeout: ", Storage.token_timeout);
            if (Storage.token == "") {
                window.vue.$router.push({path: "/login"});
            }

            if (now > Storage.token_timeout) {
                Storage.token = "";
                window.vue.$router.push({path: "/login"});


            }
            this.loading = true;


        },
        methods: {

            onLogout() {
                Modal.confirm({
                    title: "退出",
                    content: "是否退出登录?",
                    onOk() {
                        Storage.token = "";
                        window.vue.$router.push({path: "/login"});
                    }
                });
            },
        }
    };
</script>
<style>
    .changerestate {
        color: #fff
    }

    .changerestate /deep/ .ant-radio-wrapper {
        color: #fff !important;
    }

    /*.router-link-active{background-color: #1890ff;}*/
    .ant-menu-inline .ant-menu-item:after, .ant-menu-vertical-left .ant-menu-item:after, .ant-menu-vertical-right .ant-menu-item:after, .ant-menu-vertical .ant-menu-item:after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        border-right: 3px solid #1890ff;
        transform: scaleY(.0001);
        opacity: 0;
        transition: transform .15s cubic-bezier(.215, .61, .355, 1), opacity .15s cubic-bezier(.215, .61, .355, 1);
        content: "";
    }
</style>
