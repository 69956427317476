<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
  import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
  import moment from "moment";
  import "moment/locale/zh-cn";
  import Storage from "./common/storage";
  // import * as Util from "./common/util";
  // import Storage from "./common/storage";

  moment.locale("zh-cn");
  export default {
    provide() {
      return {
        reload: this.reload
      }
    },
    created() {
      let now = Date.now() / 1000;
      //console.log("now: ", now);
      //console.log("token_timeout: ", Storage.token_timeout);
      if (Storage.token == "") {

        window.vue.$router.push({path: "/login"});
      }

      if (now > Storage.token_timeout) {
        Storage.token = "";

        window.vue.$router.push({path: "/login"});


      }
      this.loading = true;
    },
    data() {
      return {
        zh_CN
      };
    },
    methods:{

      reload() {
        // this.isRouterAlive = false
        // this.$nextTick(function () {
        //     this.isRouterAlive = true
        // })
        console.log("开始刷新");

      }

    },
  };
  // require('vue-image-lightbox/dist/vue-image-lightbox.min.css')
  // Use only when you are using Webpack
</script>
<style>
#app {
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
