<template>
    <span></span>
</template>
<script>
    export default {
        name: "refresh",
        beforeCreate(){
            this.$router.push(this.$route.query.path)
        },
    };
    // this.$router.push({path:'/refresh',query:{path:this.$route.fullPath}})
</script>
