import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../../src/layout/index.vue' // 主框架路径
import refresh from '../views/refresh.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    component: Layout,
    redirect: '/home', //访问/重定向到/home


},
    {
        path: '/home',
        component: Layout,
        children: [
            {
                path: '/',
                component: () => import('../views/Dashboard.vue'),
                name: 'home',

            },{
                path: '/webindex',
                component: () => import('../views/webindex.vue'),
                name: 'home',

            },
            {
                path: '/adminList',
                name: 'adminList',
                component: () => import('../views/admin_list.vue'),
            },
            {
                path: '/adminGroup',
                name: 'adminGroup',
                component: () => import('../views/admin_group.vue'),
            },
            {
                path: '/adminPermiss',
                name: 'adminPermiss',
                component: () => import('../views/admin_permiss.vue'),
            },
            {
                path: '/articleCategory',
                name: 'articleCategory',
                component: () => import('../views/articleCategory.vue'),
            },
            {
                path: '/articleComments',
                name: 'articleComments',
                component: () => import('../views/articleComments.vue'),
            },
            {
                path: '/articlemanger',
                name: 'articlemanger',
                component: () => import('../views/articlemanger.vue'),
            },
            {
                path: '/specialmanger',
                name: 'specialmanger',
                component: () => import('../views/specialmanger.vue'),
            },
            {
                path: '/addspecial',
                name: 'addspecial',
                component: () => import('../views/addspecial.vue'),
            },{
                path: '/addarticle',
                name: 'addarticle',
                component: () => import('../views/addarticle.vue'),
            },
            {
                path: '/editarticle',
                name: 'editarticle',
                component: () => import('../views/editarticle.vue'),
            },{
                path: '/editspecial',
                name: 'editspecial',
                component: () => import('../views/editspecial.vue'),
            },
            {
                path: '/productionmanger',
                name: 'productionmanger',
                component: () => import('../views/productionmanger.vue'),
            },
            {
                path: '/addproduction',
                name: 'addproduction',
                component: () => import('../views/addproduction.vue'),
            },{
                path: '/editproduction',
                name: 'editproduction',
                component: () => import('../views/editproduction.vue'),
            },
            {
                path: '/recruitmentmanger',
                name: 'recruitmentmanger',
                component: () => import('../views/recruitmentmanger.vue'),
            },
            {
                path: '/addrecruitment',
                name: 'addrecruitment',
                component: () => import('../views/addrecruitment.vue'),
            },
            {
                path: '/editrecruitment',
                name: 'editrecruitment',
                component: () => import('../views/editrecruitment.vue'),
            },

        ]
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/login.vue'),
    },
    {
        path: '/refresh',
        name: 'refresh',
        component: refresh
    },
]

const router = new VueRouter({
    routes,
    mode: 'hash'
})

export default router
