import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


// antds
import Antd, {Modal} from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import VueParticles from 'vue-particles'
Vue.config.productionTip = false


// 网络部分
import Axios from 'axios'
import Config from './config'
// import * as message from "ant-design-vue";
import moment from "moment";
import Storage from "./common/storage";

Vue.use(VueParticles);

// 导入组件库
// import VueDragFormdesign from 'vue-drag-formdesign'
// import 'vue-drag-formdesign/lib/vue-drag-formdesign.css'
// Vue.use(VueDragFormdesign);


/* eslint-disable no-new */

(async function () {
  Axios.defaults.baseURL = Config.data.server_url
  Axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
  Axios.defaults.headers.get['Content-Type'] = 'application/x-www-form-urlencoded'
  Vue.prototype.$get = Axios.get
  Vue.prototype.$post = Axios.post
  Vue.prototype.$config = Config.data



  Vue.use(Antd);
  Vue.config.productionTip = false
  window.vue = new Vue({
    router,
    components: {App},
    store,
    render: h => h(App)
  }).$mount('#app')

})();
// 处理接收内容

// 定义一个全局过滤器实现日期格式化
Vue.filter('datefmt', function (input, fmtstring) {
  return moment.unix(input).format(fmtstring)
})

Axios.interceptors.response.use(response => {
  function gotoLogin() {
    if (window.vue.$router.history.current.fullPath != "./#/login") {
      window.vue.$router.push({path: "./#/login"});
    }
  }

  if (response.status != 200) {
    console.log('状态错误跳转到 登录', response.status)
    gotoLogin()
    return {code: -1}
  }

  // 正常结果
  if (response.data.code  == -1) {
    Modal.error({
      title: response.data.msg
    });
    return
  }
  if (response.data.code == 200) {

    return response.data;
  }

  // 不正常结果

  // if (response.data.code == -1) {
  //     // this.$message.info(response.data.msg);
  //     // alert(response.data.msg)
  //     // message.config({
  //     //     top: `100px`,
  //     //     duration: 2,
  //     //     maxCount: 3,
  //     // });
  //     // this.$message.error(response.data);
  // }
  if (response.data.code == -999) {
    // Modal.error({
    //     title: response.data.msg
    // });

    console.log('登录失效踢回登录', response.data)
    Storage.token = "";
    window.location = './#/login';
    // window.vue.$router.push({path: "./#/login"});
    return response.data;
  }
  // if (response.data.code == -666) {
  //     // Modal.error({
  //     //     title: response.data.msg
  //     // });
  //     Storage.token = "";
  //     console.log('登录失效踢回登录', response.data)
  //     window.vue.$router.push({path: "./#/login"});
  //     // Modal.error({
  //     //     title: response.data.msg
  //     // });
  //     return response.data;
  // }
  // // 口令错误跳转登录
  // if (response.data.code == Err.TOKEN_ERROR) {
  //     console.log('口令错误跳转到 登录', response.data)
  //     gotoLogin()
  //     return response.data;
  // }

  // 其他错误提示
  // console.log(ErrStr(response.data.code));
  return response.data;
}, error => {
  if (error.response) {
    this.$message.info(error.response.status);
    // alert()
    return Promise.reject(error.response.data);
  }
},function (error) {
  //处理不是200的结果
  if (401 === error.response.status) {
    window.location = './#/login';
  } else {
    return Promise.reject(error);
  }

});

Vue.directive('enterNumber', {
  inserted: function (el) {
    el.addEventListener("keypress",function(e){
      e = e || window.event;
      let charcode = typeof e.charCode == 'number' ? e.charCode : e.keyCode;
      let re = /\d/;
      if(charcode == 46){
        if(el.value.includes('.')){
          e.preventDefault();
        }
        return;
      }else if(!re.test(String.fromCharCode(charcode)) && charcode > 9 && !e.ctrlKey){
        if(e.preventDefault){
          e.preventDefault();
        }else{
          e.returnValue = false;
        }
      }
    });
  }
});
